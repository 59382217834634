.container {
  position: relative;
  margin: 0 auto;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  max-width: 28rem;
  background-color: white;
  padding-bottom: 5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.topbar {
  position: sticky;
  top: 0;
  padding: 1rem;
  background-color: #2d4a8a;
  // border-bottom: 4px solid white;
  z-index: 100;

  .logo {
    height: 1.2rem;
  }

  .language {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e2e53;
    border-radius: 4rem;
    padding: 4px;
    gap: .5rem;

    .selected {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 50%;
      height: 100%;
      padding: 3px;
      box-sizing: border-box;
      z-index: 1;
      user-select: none;
      transition: .2s ease;

      .button {
        background-color: #2d4a8a;
        border: 4px solid white;
        border-radius: 2rem;
        height: 100%;
        box-sizing: border-box;
      }

      &.id {
        transform: translate(100%, -50%);
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 50%;
      padding: .3rem;
      color: white;
      z-index: 2;

      .icon {
        height: 1.2rem;
      }
    }
  }
}

.voucher {
  position: relative;
  padding-top: 60%;
  background-color: #d61027;
  // background-color: #4c2a86;
  margin: 1rem;
  border-radius: 1rem;
  overflow: hidden;

  .side {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(1.5);
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translateY(-50%) rotate(45deg);
      width: 20px;
      height: 20px;
      background-color: white;
      border-radius: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -12px;
      transform: translateY(-50%) rotate(45deg);
      width: 20px;
      height: 20px;
      background-color: white;
      border-radius: 5px;
    }
  }

  .logo {
    position: absolute;
    top: 3rem;
    left: 1rem;
    height: 3rem;
  }

  .mascot {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 95%;
  }

  .badge {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: white;
  }

  .align {
    position: absolute;
    top: 1rem;
    right: .4rem;
    height: 2rem;
    filter: contrast(0) brightness(100);
  }

  .nominal {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    white-space: nowrap;
    color: white;
  }

  .redeemed {
    position: absolute;
    top: 10%;
    right: -100%;
    width: 80%;
    background-color: rgba(0, 0, 0, .7);
    color: white;
    transform: rotate(45deg);
    padding: 1rem;
    z-index: 9;

    &.used {
      animation: show-redeemed 1s ease forwards;
    }
  }
}

.order-id {
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: .7rem;
  color: #8e8e8e;
  text-align: center;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  padding: .3rem;
  margin-top: -.5rem;
  margin-bottom: .5rem;

  .copy {
    height: .7rem;
    margin-left: .2rem;
    opacity: .5;
  }
}

.redeemed {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #fff6d5;
  padding: 1rem;
  font-family: 'Jost', sans-serif;
  color: #545454;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 1rem;
  overflow: hidden;

  .success {
    height: 1.5rem;
  }
}

.voucher-note {
  position: relative;
  background-color: #fff6d5;
  padding: 1rem;
  font-family: 'Jost', sans-serif;
  color: #e01f21;
  font-size: .9rem;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 1rem;
  overflow: hidden;
}

.info {
  padding: 1rem 0;
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;

  .voucher-title {
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #545454;
    text-align: center;
  }

  .voucher-nominal {
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: #545454;
    text-align: center;
  }

  .line {
    background-color: #545454;
    height: 2px;
    margin: .5rem auto;
    width: 10rem;
  }

  .expired-title {
    font-family: 'Jost', sans-serif;
    font-size: 1rem;
    text-align: center;
    color: #8e8e8e;
    margin-top: .5rem;
  }

  .expired-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-family: 'Jost', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: #545454;
    text-align: center;

    .icon {
      height: .8rem;
    }
  }

}

.button-wrapper {
  // position: absolute;
  // bottom: 4rem;
  // width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  .button {
    background-color: #2d4a8a;
    padding: 1rem;
    border-radius: 5rem;
    text-align: center;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #3a5fae;
    }
  }
}

.modal-confirm {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  max-width: 28rem;
  background-color: rgba(0, 0, 0, .5);
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all .5s ease;

  &.show {
    visibility: visible;
    opacity: 1;

    .box {
      transform: translate(-50%, -50%) scale(1);
      transition: all .3s ease;
    }
  }

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 80%;
    transform: translate(-50%, -50%) scale(1.2);
    transition: all .5s ease;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    padding: 1rem;

    .title {
      font-family: 'Jost', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      color: #545454;
    }

    .content {
      font-family: 'Jost', sans-serif;
      font-size: .9rem;
      text-align: center;
      color: #545454;
      margin: .5rem 0;
    }

    .button-confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      margin-top: .5rem;

      .button {
        background-color: white;
        border: 1px solid #545454;
        padding: .3rem;
        border-radius: .5rem;
        text-align: center;
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        min-width: 5rem;
        color: #545454;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        &.primary {
          background-color: #2d4a8a;
          color: white;

          &:hover {
            background-color: #3a5fae;
          }
        }
      }
    }
  }
}

.tnc {
  padding: 1rem;
  text-align: center;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: .9rem;
  color: #545454;
  text-decoration: underline;
  margin-top: .5rem;
}

.modal-tnc {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  max-width: 28rem;
  background-color: rgba(0, 0, 0, .5);
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all .5s ease;

  &.show {
    visibility: visible;
    opacity: 1;

    .tnc-box {
      transform: translate(-50%, -50%) scale(1);
      transition: all .3s ease;
    }
  }

  .tnc-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%) scale(1.05);
    transition: all .5s ease;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;

    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: .5rem;
      right: .5rem;
      width: 1.5rem;
      height: 1.5rem;
      background-color: #333;
      border-radius: .3rem;
      cursor: pointer;

      &:hover {
        background-color: #555;
      }

      .icon {
        height: .8rem;
        filter: contrast(0) brightness(100);
      }
    }

    .title {
      font-family: 'Jost', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      color: #545454;
      border-bottom: 1px solid #ddd;
      padding: .5rem;
    }

    .content {
      font-family: 'Jost', sans-serif;
      font-size: .9rem;
      text-align: center;
      line-height: 1.5rem;
      color: #545454;
      margin: 0;
      min-height: 20vh;
      max-height: 70vh;
      padding: 1rem;
      overflow: auto;
    }

    .button-confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      margin-top: .5rem;

      .button {
        background-color: white;
        border: 1px solid #545454;
        padding: .3rem;
        border-radius: .5rem;
        text-align: center;
        font-family: 'Jost', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        min-width: 5rem;
        color: #545454;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        &.primary {
          background-color: #2d4a8a;
          color: white;

          &:hover {
            background-color: #3a5fae;
          }
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  max-width: 28rem;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
  visibility: hidden;
  overflow: auto;
  transition: all .5s ease;

  &.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, .8);
    transition: all 2s ease;

    .code {
      transform: translate(-50%, -50%);
      transition: all .5s .2s ease;
    }
  }

  .code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 200%);
    transition: all .3s ease;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    padding: 1rem .5rem;

    .voucher-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: 'Jost', sans-serif;
      font-weight: bold;
      font-size: 1.2rem;
      color: #545454;
      text-align: center;

      .copy {
        height: 1.2rem;
        margin-left: .3rem;
      }
    }

    .choose-code {
      position: relative;
      display: flex;
      margin: 1rem;
      background-color: #eee;
      border-radius: 2rem;

      .selected {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 50%;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
        z-index: 1;
        user-select: none;
        transition: .3s ease;

        .button {
          background-color: white;
          border-radius: 2rem;
          height: 100%;
          box-sizing: border-box;
        }

        &.qr {
          transform: translate(100%, -50%);
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .2rem;
        flex-basis: 50%;
        padding: 1rem;
        z-index: 2;
        cursor: pointer;

        &.active {
          background-color: white;
        }

        .icon {
          height: .8rem;
        }

        span {
          font-family: 'Jost', sans-serif;
          font-weight: bold;
          font-size: .7rem;
          white-space: nowrap;
          color: #545454;
        }
      }
    }
  }
}

.contact {
  text-align: center;
  color: #545454;
  font-family: 'Jost', sans-serif;
  font-size: .8rem;
  text-align: center;
}

.whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 0 1rem;
  margin-top: .5rem;

  .whatsapp-icon {
    height: 1.4rem;
  }

  span {
    color: #545454;
    text-decoration: none;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 1rem;
  }
}

.voucher-not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;

  .icon {
    height: 3rem;
    margin-bottom: 1rem;
  }
}

@keyframes show-redeemed {
  0% {
    right: -100%;
  }

  100% {
    right: -30%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 #acc6ff;
  }

  100% {
    box-shadow: 0 0 0 1rem transparent;
  }
}